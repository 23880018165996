import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography } from '@mui/material';

export const PasswordCriteria = ({ isValid, text }) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      {isValid ? (
        <CheckCircleIcon sx={{ color: '#e6007e' }} />
      ) : (
        <CancelIcon sx={{ color: '#ccc' }} />
      )}
      <Typography sx={{ color: isValid ? '#e6007e' : '#9D9D9D', marginLeft: '8px' }}>
        {text}
      </Typography>
    </Box>
  );
};
