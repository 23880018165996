import React, { useState } from 'react';
import './Auth.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Images } from './Images';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitButton } from './SubmitButton';
import { ThemeProvider } from "@mui/material";
import { MUItheme } from './MUItheme';
import { HiddenInput } from './HiddenInput';

export function CreatePassword() {

  const location = useLocation();
  const nombre = location.state.nombre;
  const apellido = location.state.apellido;
  const mail = location.state.mail;
  const [responseData, setResponseData] = useState({});
  const [responseStatus, setResponseStatus] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(false);
    setErrorMatch(false);
    setErrorFormat(false);
    setLoading(true);
    
    try {
        if (formData.password != formData.passwordConfirmation){
          setErrorMatch(true);
          toast.error('Las contraseñas no coinciden', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
        
        else{
          toast.success('Creando tu cuenta...', {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: nombre,
                        lastName: apellido,
                        mail,
                        password: formData.password,
                        recaptchaToken,
                    }),
                });
                if (response.status == 201) {
                    localStorage.setItem('jwtToken', response.data.access_token);
                    localStorage.setItem('userId', response.data.id);
                    setLoading(false);
                    navigate('/profile');       
                  } else{
                    console.log("ERROR", responseData.error);
                    setError(true);
                    toast.error(responseData.error||'Hubo un error al crear tu cuenta', {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                  }
        
            } catch (error) {
                // Handle errors here
                console.log(error.message);
                console.error(error);
                
            }
        }
    } catch (error) {
        setErrorFormat(true);
        toast.error('Hubo un error al crear tu cuenta', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } finally {
        setLoading(false);
    }
  
  };

  const handleRecaptcha = (value) => {
    setRecaptchaToken(value);
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMatch, setErrorMatch] = useState(false);
  const [errorFormat, setErrorFormat] = useState(false);
  return (
    <GoogleReCaptchaProvider reCaptchaKey= {process.env.REACT_APP_CLAVE_SITIO}>
      <ToastContainer />
      <div className='main-container'>
        <Images />
        <div className='main-container-login'>
            <form onSubmit={handleLogin} className="form-container">
            <div className='clave-container'>
            <ThemeProvider theme={MUItheme}>
                <h3 className='message-login'>Crea tú clave</h3>
                <h3 className='enter-text'>Utiliza al menos  8 caracteres, 1 mayúscula, 1 minúscula, 1 número y un símbolo.</h3>
                <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
                <HiddenInput text='Contraseña' type={'password'} onInputChange={handleInputChange}/>
                <HiddenInput text='Repite la contraseña' type={'passwordConfirmation'} onInputChange={handleInputChange}/>
            </ThemeProvider>
            </div>
            <div className="recaptcha-container">
                <GoogleReCaptcha onVerify={handleRecaptcha} action="submit"  />
            </div>
            <SubmitButton handleLogin={handleLogin} buttonColor="#E6007E" buttonText="Entrar"/>
            </form>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
  
}

export default CreatePassword;