
import React, { useState, useEffect } from 'react';
import "./Editar.css";
import cityImage from '../../img/Santiago.png';
import Header from '../Header/Header';
import { NextButton } from '../Form//NextButton';
import { BackButton } from '../Form/BackButton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NormalInput }  from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { PasswordCriteria } from './PasswordCriteria';
import Box from '@mui/material/Box';

const  change_password = async (email, currentPassword, newPassword) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                currentPassword,
                newPassword,
            }),
        });
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}


//Componente Formulario
const EditPassword = () => {

    const [formData, setFormData] = useState({
        email: '',
        currentPassword: '',
        newPassword: '',
      });

    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        hasUpperCase: false,
        hasNumberOrSymbol: false,
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
          ...formData,
          [id]: value,
        });
    
        if (id === 'newPassword') {
          validatePassword(value);
        }
      };
    
      // Función que valida la contraseña
      const validatePassword = (password) => {
        setPasswordCriteria({
          minLength: password.length >= 8,
          hasUpperCase: /[A-Z]/.test(password),
          hasNumber: /[0-9]/.test(password),
          hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(password)
        });
      };
    

    

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/profile');
    }


    const handleChange = async (event) => {
        console.log("holiii")
        const body = {
            email: formData.email, 
            currentPassword: formData.currentPassword, 
            newPassword: formData.newPassword
        };
        console.log(JSON.stringify(body)); // Log the body for debugging
        event.preventDefault();
    
        try {
            toast.success('Password changed successfully!', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            const response = await change_password(formData.email, formData.currentPassword, formData.newPassword);
    
            if (response.ok) {
                navigate('/profile'); // Navigate to profile page on success
            } else {
                throw new Error('Failed to change password'); // Throw an error to be caught in the catch block
            }
        } catch (error) {
            console.error(error);
            toast.error(`Error: ${error.message}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
      };
    
    return (
        <div>
            <Header />
            <img src={cityImage} alt="City Background" className="image-city-editar" />
            <div className='fondo_rosado_editarContraseña'></div>
            <div className='fondo_blanco_editarContraseña'></div>
            <div className='container-editarContraseña'>
            <h3 className='message-forgot-password'>Cambiar Contraseña</h3>
                <h3 className='instructions-forgot-password'>{'Ingresa tu correo para cambiar tu contraseña'}</h3>
                <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
                <NormalInput text={'Correo electrónico'} onInputChange={handleInputChange} value={'email'} />
                <HiddenInput text={'Contraseña Actual'} onInputChange={handleInputChange} value={'currentPassword'} type={'currentPassword'} />
                <HiddenInput text={'Contraseña Nueva'} onInputChange={handleInputChange} value={'newPassword'} type={'newPassword'}/>

                <Box mt={4} ml={12}>
                    <PasswordCriteria isValid={passwordCriteria.minLength} text="8 caracteres" />
                    <PasswordCriteria isValid={passwordCriteria.hasUpperCase} text="1 mayúscula" />
                    <PasswordCriteria isValid={passwordCriteria.hasNumber && passwordCriteria.hasSymbol} text="1 número y 1 símbolo" />
                </Box>
                <div className='button-container-editarcontrasena' >
            <BackButton buttonText="Cancelar" buttonColor="#5A5A5A" marginLeft="-5%" marginTop="-9.3%" handleBack={handleCancel}/>
            <NextButton  buttonText="Enviar" buttonColor="#e6007e" marginLeft="20%" marginTop="-12%" handleNext={handleChange}/> 
            </div>
                    
            </div>
            
        </div>
        
    );
};

export default EditPassword;