import React, { useState, useEffect } from 'react';
import { Images } from './Images';
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { SubmitButton } from './SubmitButton';
import './Auth.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RegisterButton } from './RegisterButton';

import { ThemeProvider } from "@mui/material";
import { MUItheme } from './MUItheme';

// Funciones para obtener datos desde la API
async function fetchPaises() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/paises`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los países:', error);
        throw error;
    }
}

async function fetchRegiones() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/regiones`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las regiones:', error);
        throw error;
    }
}

async function fetchProfesiones() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/profesiones`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las profesiones:', error);
        throw error;
    }
}

async function fetchUniversidades() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/universidades`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las universidades:', error);
        throw error;
    }
}

async function fetchCargos() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/cargos`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los cargos:', error);
        throw error;
    }
}

async function fetchIndustrias() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/industrias`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las industrias:', error);
        throw error;
    }
}

async function fetchAniosExperiencia() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/rango_anos`);
        const aniosOrdenados = response.data.sort((a, b) => {
            // Extraer los números iniciales del rango para comparar
            const numA = parseInt(a.rango.split('-')[0], 10);
            const numB = parseInt(b.rango.split('-')[0], 10);
            return numA - numB; // Ordenar en forma ascendente
        });
        return aniosOrdenados;
    } catch (error) {
        console.error('Error al obtener los años de experiencia:', error);
        throw error;
    }
}

async function fetchCompetencias() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/competencias`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las competencias:', error);
        throw error;
    }
}

async function fetchAreasExperiencia() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/areas`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las areas:', error);
        throw error;
    }
}

async function fetchDisponibilidades() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/disponibilidades`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las disponibilidades:', error);
        throw error;
    }
}

async function fetchJornadas() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/jornadas`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las jornadas:', error);
        throw error;
    }
}

async function fetchModalidades() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/modalidades`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las modalidades:', error);
        throw error;
    }
}

async function fetchIdiomas() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/idiomas`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las idiomas:', error);
        throw error;
    }
}

async function fetchConocio() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/conocio_wot`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener como conocio WoT:', error);
        throw error;
    }
}

async function fetchPersonalidad() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/obtener_listas/formularios`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener formulario de personalidad:', error);
        throw error;
    }
}

export function Login() {
    const [datosPaises, setDatosPaises] = useState([]);
    const [datosRegion, setDatosRegion] = useState([]);
    const [datosProfesiones, setDatosProfesiones] = useState([]);
    const [datosUniversidades, setDatosUniversidades] = useState([]);
    const [datosCargos, setDatosCargos] = useState([]);
    const [datosIndustrias, setDatosIndustrias] = useState([]);
    const [aniosExperiencia, setAniosExperiencia] = useState([]);
    const [competencias, setCompetencias] = useState([]);
    const [areasExperiencia, setAreasExperiencia] = useState([]);
    const [disponibilidades, setDisponibilidades] = useState([]);
    const [jornadas, setJornadas] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [idiomas, setIdiomas] = useState([]);
    const [conocio, setConocio] = useState([]);
    const [personalidades, setPersonalidades] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const paises = await fetchPaises();
                setDatosPaises(paises);

                const regiones = await fetchRegiones();
                setDatosRegion(regiones);

                const profesiones = await fetchProfesiones();
                setDatosProfesiones(profesiones);
                
                const universidades = await fetchUniversidades();
                setDatosUniversidades(universidades);

                const cargos = await fetchCargos();
                setDatosCargos(cargos);

                const industrias = await fetchIndustrias();
                setDatosIndustrias(industrias);

                const anios = await fetchAniosExperiencia();
                setAniosExperiencia(anios);

                const competenciasData = await fetchCompetencias();
                setCompetencias(competenciasData);

                const areas = await fetchAreasExperiencia();
                setAreasExperiencia(areas);

                const disponibilidadesData = await fetchDisponibilidades();
                setDisponibilidades(disponibilidadesData);

                const jornadasData = await fetchJornadas();
                setJornadas(jornadasData);

                const modalidadesData = await fetchModalidades();
                setModalidades(modalidadesData);

                const idiomasData = await fetchIdiomas();
                setIdiomas(idiomasData);

                const conocioData = await fetchConocio();
                setConocio(conocioData);

                const personalidadesData = await fetchPersonalidad();
                setPersonalidades(personalidadesData);
                

            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };
        fetchData();
    }, []);

    const [formData, setFormData] = useState({
        mail: '',
        contraseña: '',
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            toast.success('Iniciando sesión...', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
    
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/login`, 
                {
                    mail: formData.mail, 
                    password: formData.contraseña
                }
            );
    
    
            if (response.status === 200) {
                localStorage.setItem('jwtToken', response.data.access_token, { expires: 1 });
                localStorage.setItem('userId', response.data.id);
                localStorage.setItem('datosPaises', JSON.stringify(datosPaises));
                localStorage.setItem('datosRegion', JSON.stringify(datosRegion));
                localStorage.setItem('datosProfesiones', JSON.stringify(datosProfesiones));
                localStorage.setItem('datosUniversidades', JSON.stringify(datosUniversidades));
                localStorage.setItem('datosCargos', JSON.stringify(datosCargos));
                localStorage.setItem('datosIndustrias', JSON.stringify(datosIndustrias));
                localStorage.setItem('aniosExperiencia', JSON.stringify(aniosExperiencia));
                localStorage.setItem('competencias', JSON.stringify(competencias));
                localStorage.setItem('areasExperiencia', JSON.stringify(areasExperiencia));
                localStorage.setItem('disponibilidades', JSON.stringify(disponibilidades));
                localStorage.setItem('jornadas', JSON.stringify(jornadas));
                localStorage.setItem('modalidades', JSON.stringify(modalidades));
                localStorage.setItem('idiomas', JSON.stringify(idiomas));
                localStorage.setItem('conocio', JSON.stringify(conocio));
                localStorage.setItem('personalidades', JSON.stringify(personalidades));
                navigate('/profile');

            }
    
        } catch (error) {
            console.error("Error en la solicitud de login:", error.response ? error.response.data : error.message);
    
            toast.error(
                error.response && error.response.data ? error.response.data.message : 'Los datos ingresados son incorrectos',
                {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            );
        }
    };
    

    return (
        <>
            <ToastContainer limit={1} />

            <div className='main-container'>
                <Images />
                <div className='rigth-container-login'>
                    <ThemeProvider theme={MUItheme}>
                        <h3 className='message-login'>Ingresa a WOT</h3>
                        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
                        <NormalInput text='Usuario' value={'mail'} onInputChange={handleInputChange} />
                        <HiddenInput text='Contraseña' onInputChange={handleInputChange} type={'contraseña'} />
                        <div className='box-user-login'>
                            <a className='button-forgot-password' href="/forgotpassword">¿Olvidaste tu contraseña?</a>
                        </div>
                        <SubmitButton handleLogin={handleLogin} buttonText="Entrar" buttonColor="#E6007E" />
                        <RegisterButton handleRegister={() => navigate('/register')} />
                        <a className='button-forgot-password'>Si necesitas ayuda escribe a help@womantalent.cl</a>
                    </ThemeProvider>
                </div>
            </div>
        </>
    );
}

export default Login;