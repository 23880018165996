import React, { useState } from 'react';
import { MUItheme } from './MUItheme';
import { ThemeProvider } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { Images } from './Images';
import { HiddenInput } from './HiddenInput';
import { SubmitButton } from './SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import { NormalInput } from './NormalInput';
import 'react-toastify/dist/ReactToastify.css';

export function EnterVerificationCode() {
  const location = useLocation();
  const mail = location.state.mail;

  const [formData, setFormData] = useState({
    code: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mail: mail, code: formData.code, newPassword: formData.password }),
      });
      if (response.status === 201) {
        navigate('/');
      }
      else if (response.status !== 201){
        toast.error('El código o la contraseña son inválidas', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <ToastContainer />
      <div className='main-container'>
          <Images />
          <div className='right-container-login'>
            <ThemeProvider theme={MUItheme}>
              <h3 className='message-forgot-password'>¿Olvidaste tu contraseña?</h3>
              <h3 className='instructions-forgot-password'>{'Ingresa el código que enviamos a tu correo y tu nueva contraseña, recuerda revisar que el correo ingresado es el mismo de tu cuenta WOT'}</h3>
              <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
              <NormalInput text={'Código de verificación'} onInputChange={handleInputChange} type={'code'} value={'code'}/>
              <HiddenInput text={'Nueva contraseña'} onInputChange={handleInputChange} type={'password'} value ={'password'}/>
              <SubmitButton handleLogin={handleLogin} buttonText={'Confirmar'} buttonColor= "#53565A"/>
            </ThemeProvider>
          </div>
      </div>
    </>
  ) 
}
export default EnterVerificationCode;